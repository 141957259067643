<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-img
                max-width="700px"
                :src="batImgInfo.roomImgRoute"
                @click="click"
                style="cursor: pointer"
        ></v-img>
    </div>
</template>

<script>

    export default {
        name: 'CampMapPop',

        components: {
        },
        data: () => ({
            batImgInfo:{}

        }),
        mounted() {
            this.$nextTick(() => {
            })
        },
        created: function(){
            // 맨위로
            window.scrollTo(0,0);

            this.getImgList();
        },
        methods: {
            click(){
                window.close();
            },
            // 배치도 이미지
            getImgList(){
                return this.$store.dispatch("admin/getRoomImg", {roomId: 'BAT'})
                    .then((resp) => {
                        setTimeout(() => {

                            this.batImgInfo = resp.message[0];

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
        }

    }
</script>



